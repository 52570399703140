import { createApp } from 'vue';
import App from './App.vue';
import VueCarousel from 'vue-carousel';
import './assets/styles.css'; // Import the global CSS

// Create the app instance
const app = createApp(App);

// Use the VueCarousel plugin
app.use(VueCarousel);

// Mount the app to the DOM
app.mount('#app');
